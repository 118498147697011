@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@aws-amplify/ui-react/styles.css";

body {
  font-family: "Noto Sans JP", sans-serif;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #0d9488;
}

/* Amplify */

[data-amplify-authenticator] {
  --amplify-components-button-link-color: #00a291;
  --amplify-components-button-link-hover-color: #00a291;
  --amplify-components-button-link-hover-background-color: #eefffd;
  --amplify-components-button-link-active-background-color: #eefffd;
  --amplify-components-button-primary-background-color: #00a291;
  --amplify-components-button-primary-hover-background-color: #027e72;
  --amplify-components-button-primary-active-background-color: #155650;
  --amplify-colors-background-tertiary: #edfffd;
  --amplify-button--primary: #00a291;
}

.amplify-tabs {
  display: none !important;
}

.amplify-heading {
  text-align: center !important;
}

.amplify-button--fullwidth,
.amplify-button--primary {
  background-color: #00a291 !important;
  color: #ffffff;
}

amplify-s3-image {
  /* --height: 200px; */
  --width: 600px;
}

#report-blade amplify-s3-image {
  --width: 800px;
}

#print-report-blade amplify-s3-image {
  --width: 550px;
}

#previous-report amplify-s3-image {
  --height: 158px;
  --width: 200px;
}

#previous-report amplify-s3-image img {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #374151;
  border-color: #374151 transparent #374151 transparent;
  animation: loader-2 1.2s linear infinite;
}
@keyframes loader-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#general-report amplify-s3-image {
  /* --height: 200px; */
  --width: 400px;
}

#report-kyk amplify-s3-image {
  /* --height: 200px; */
  --width: 120px;
  margin-left: 30px;
}

#report-additional amplify-s3-image {
  --width: 300px;
  margin-left: 30px;
}

/* Print */

@page {
  size: auto;
  margin: 5mm;
}

@media print {
  .hide-print {
    display: none;
  }

  .pagebreak {
    page-break-before: always;
  }

  .pagebreakafter {
    page-break-after: always;
  }

  #general-report amplify-s3-image {
    /* --height: 200px; */
    --width: 200px !important;
  }

  .avoidbreak {
    break-inside: avoid;
  }

  tbody {
    /* display: block; */
    break-inside: avoid;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  table tfoot thead {
    break-inside: auto;
    overflow: hidden;
  }
}

/* Reports */

.inpection-header::after {
  line-height: 1em;
  content: "_";
  color: white;
  display: block;
}

.print-font {
  font-size: 9px;
}

.pending-report-print-font {
  font-size: 7px;
}

#print-report-spd span,
#print-report-pcs span {
  font-size: 9px;
  line-height: 10px;
}

#print-report-inspection svg,
#print-report-mechanical svg,
#print-report-spd svg,
#print-report-blade svg,
#print-report-voltage svg,
#print-report-header-insulation svg,
#print-report-insulation svg,
#print-report-pcs svg {
  height: 0.7rem;
  width: 0.7rem;
}

#report-inspection {
  height: 9034px;
}

.table-turbine-inspections {
  /* height: 758px; */
}

/* Loader */

.loader {
  margin: 0 auto;
  color: #115e59;
  width: 69px;
  height: 36px;
  --c: radial-gradient(farthest-side, currentColor 90%, #0000);
  background: var(--c), var(--c), var(--c), var(--c);
  background-size: 12px 12px;
  background-repeat: no-repeat;
  animation: d3 1s infinite linear;
}

@keyframes d3 {
  0% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%,
      calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
  }
  16.67% {
    background-position: calc(0 * 100% / 3) 0, calc(1 * 100% / 3) 50%,
      calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
  }
  33.33% {
    background-position: calc(0 * 100% / 3) 100%, calc(1 * 100% / 3) 0,
      calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
  }
  50% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 100%,
      calc(2 * 100% / 3) 0, calc(3 * 100% / 3) 50%;
  }
  66.67% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%,
      calc(2 * 100% / 3) 100%, calc(3 * 100% / 3) 0;
  }
  83.33% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%,
      calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 100%;
  }
  100% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%,
      calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
  }
}

/* Loader 2 */
.loader-2 {
  display: inline-block;
  /* width: 80px;
  height: 80px; */
}
.loader-2:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #115e59;
  border-color: #115e59 transparent #115e59 transparent;
  animation: loader-2 1.2s linear infinite;
}
@keyframes loader-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
